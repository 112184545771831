var render, staticRenderFns
import script from "./Titulo.vue?vue&type=script&lang=js&"
export * from "./Titulo.vue?vue&type=script&lang=js&"
import style0 from "./Titulo.styl?vue&type=style&index=0&id=33605200&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33605200",
  null
  
)

export default component.exports