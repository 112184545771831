<script>
export default {
    name: "component-titulo",
    functional: true,
    render(h, ctx) {
        let { content, level, attributes } = ctx.props.contents
        let levelTag = 'h1'

        switch (level) {
            case 6: levelTag = 'h6'; break;
            case 5: levelTag = 'h5'; break;
            case 4: levelTag = 'h4'; break;
            case 3: levelTag = 'h3'; break;
            case 2: levelTag = 'h2'; break;
            case 1:
            default: levelTag = 'h1'; break;
        }
        return h(levelTag, { ...attributes, domProps: { innerHTML: content } })
    },
}
</script>

<style lang="stylus" scoped src="./Titulo.styl"></style>
